import React from 'react'




export default function AboutPage() {
    return (
    
        <>
  Hi Bishwas Here, I am a Freelance Web Developer, who started with 0 experience and made a lot with 3+ years of experience.
</>
    );
  }